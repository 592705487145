<mat-accordion *ngIf="(tasks.$ | async)?.length as itemCount" test-id="upload-widget">
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header i18n>Uploading {{ itemCount }} item{ itemCount,
      plural, =1 {} other {s} }
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="24px" class="content">

      <mat-divider></mat-divider>
      <caption class="mat-caption" i18n>We’re uploading your files to our servers. That can take a while,
        thank you for your patience! You can continue using the platform</caption>

      <div fxLayout="column-reverse" fxLayoutGap="24px">
        <ng-container *ngFor="let task$ of tasks.$ | async; let i = index">
          <ng-container [ngSwitch]="task$ | state | async">
            <div fxLayout="row" fxLayoutGap="8px">
              <img [asset]="task$.snapshot.ref.name | fileTypeImage">

              <div fxLayout="column" fxLayoutAlign="start start">
                <caption class="caption">{{ task$.snapshot.ref.name | maxLength: 25 }}</caption>
                <mat-progress-bar [value]="task$ | progress | async" *ngIf="(task$ | state | async) !== 'canceled'">
                </mat-progress-bar>

                <!-- Finished -->
                <ng-template ngSwitchCase="success">
                  <caption class="mat-caption succeeded"><em i18n>Completed</em></caption>
                </ng-template>

                <!-- Paused -->
                <ng-template ngSwitchCase="paused">
                  <caption class="mat-caption"><em i18n>Paused</em></caption>
                </ng-template>

                <!-- Running -->
                <ng-template ngSwitchCase="running">
                  <caption class="mat-caption"><em i18n>Uploading</em></caption>
                </ng-template>

                <!-- Canceled -->
                <ng-template ngSwitchCase="canceled">
                  <caption class="mat-caption"><em i18n>Upload canceled</em></caption>
                </ng-template>

                <!-- Error -->
                <ng-template ngSwitchCase="error">
                  <caption class="mat-caption error"><em i18n>An error occured!</em></caption>
                </ng-template>
              </div>

              <!-- Running -->
              <ng-template ngSwitchCase="running">
                <button mat-icon-button (click)="task$.pause()">
                  <mat-icon svgIcon="pause_circle"></mat-icon>
                </button>
                <button mat-icon-button (click)="task$.cancel()">
                  <mat-icon svgIcon="cancel"></mat-icon>
                </button>
              </ng-template>

              <!-- Canceled -->
              <ng-template ngSwitchCase="canceled">
                <button mat-icon-button (click)="remove(i)">
                  <mat-icon svgIcon="cancel"></mat-icon>
                </button>
              </ng-template>

              <!-- Paused -->
              <ng-template ngSwitchCase="paused">
                <button mat-icon-button (click)="task$.resume()">
                  <mat-icon svgIcon="play_circle"></mat-icon>
                </button>
                <button mat-icon-button (click)="cancel(task$)">
                  <mat-icon svgIcon="cancel"></mat-icon>
                </button>
              </ng-template>

              <!-- Finished -->
              <ng-template ngSwitchCase="success">
                <button mat-icon-button (click)="remove(i)">
                  <mat-icon svgIcon="check_circle"></mat-icon>
                </button>
              </ng-template>

            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
