<ng-container *ngIf="(stepData$ | async) as stepData">
  <article class="page-data" fxLayout="column" fxLayoutAlign="center start">
    <b class="mat-subtitle-2" *ngIf="stepData.length">
      Step {{ stepData.index }}/{{ stepData.length }}
    </b>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="stepData.time">
      <mat-icon svgIcon="access_time"></mat-icon>
      <span class="mat-caption">{{ stepData.time }} min ≃</span>
    </div>
  </article>
</ng-container>
