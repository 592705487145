<section fxLayout="column" fxLayoutAlign="start center">
  <article fxLayout="row" fxLayoutAlign="space-between start" class="full-width">
    <header *ngIf="page$ | async as page" fxLayout="column" fxFlex.gt-sm="75">
      <h4 class="subtitle">{{ page.title }}</h4>
      <h1 class="title">{{ page.route.label }}</h1>
      <p class="mat-caption">(*) Mandatory fields</p>
      <p *ngIf="page.route.disclaimer" class="mat-caption">
        {{ page.route.disclaimer }}
      </p>
    </header>
  </article>
  <article fxLayout="column" class="full-width">
    <ng-content></ng-content>
  </article>
</section>