<global-modal>
  <section fxLayout="column" fxLayoutAlign="center center">
    <h2>{{ data.title || 'Confirm' }}</h2>
    
    <p class="mat-body-2">{{ data.question || 'Do you confirm ?' }}</p>
    <p class="mat-body-2" *ngIf="data.advice">
      {{ data.advice }} 
      <ng-container *ngIf="data.additionalData?.length">
        <ul>
          <li *ngFor="let item of data.additionalData"><strong>{{ item }}</strong></li>
        </ul>
        <br/>
      </ng-container>
      <button *ngIf="data.intercom" (click)="openIntercom()"><strong>{{ data.intercom }}</strong></button>
    </p>
    
    <mat-dialog-actions fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
      <button test-id="confirm" mat-flat-button color="primary" (click)="confirm()">
        {{ data.confirm || 'Confirm' }}
      </button>
      <button mat-button color="primary" (click)="close()">
        {{ data.cancel || 'Cancel' }}
      </button>
    </mat-dialog-actions>
  </section>
</global-modal>
