<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <ng-container *ngIf="(mode$ | async) === 'over'">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
  </ng-container>
  <ng-content select="header, [tunnel-header]"></ng-content>
  <a mat-icon-button (click)="redirect()" test-id="close-tunnel">
    <mat-icon svgIcon="close"></mat-icon>
  </a>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav [opened]="(mode$ | async) === 'side'" [mode]="mode$ | async" test-id="steps-list">
    <ng-content select="aside"></ng-content>
  </mat-sidenav>

  <mat-sidenav-content tunnelSpecificities>
    <!-- Skip to next -->
    <ng-container *ngIf="next$ | async as next">
      <div fxLayout="row" fxLayoutAlign="end center">
        <a mat-button color="primary" [routerLink]="next.path" class="mat-subtitle-2">
          <span>Skip and do later</span>
          <mat-icon iconPositionEnd svgIcon="arrow_forward"></mat-icon>
        </a>
      </div>
    </ng-container>

    <!-- Main Content -->
    <main [@routeAnimation]="animationOutlet(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>

    <!-- Previous / Next -->
    <footer fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="center center" class="footnote">
      <ng-container *ngIf="previous$ | async as previous">
        <a test-id="back" mat-button color="accent" [routerLink]="previous.path" fxLayoutAlign="center center">
          <mat-icon svgIcon="arrow_back"></mat-icon>
          <span>Back</span>
        </a>
      </ng-container>
      <ng-container *ngIf="next$ | async as next">
        <a test-id="next" mat-flat-button color="accent" [routerLink]="next.path" fxLayoutAlign="center center">
          <span>Next</span>
          <mat-icon iconPositionEnd svgIcon="arrow_forward"></mat-icon>
        </a>
      </ng-container>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>