<section class="surface" *ngIf="!hasAccepted">
  <button mat-icon-button (click)="openCookieModal()">
    <mat-icon
      svgIcon="help"
      i18n-matTooltip
      matTooltip="More details">
    </mat-icon>
  </button>
  <img asset="cookie.png" alt="cookie">

  <p i18n>This website uses cookies. Some of them are essential to the service, some are just here to improve your experience.</p>
  <form [formGroup]="form">
    <article>
      <label>
        <span i18n>Essential</span>
        <mat-icon
          svgIcon="info"
          [matTooltip]="essentialCookies">
        </mat-icon>
      </label>
      <mat-checkbox disabled [checked]="true"></mat-checkbox>
    </article>
    <article>
      <label>
        <span i18n>Behavioral analytics</span>
        <mat-icon
          svgIcon="info"
          [matTooltip]="analyticsCookies">
        </mat-icon>
      </label>
      <mat-checkbox color="primary" formControlName="hotjar"></mat-checkbox>
    </article>
    <article>
      <label>
        <span i18n>Chat</span>
        <mat-icon
          svgIcon="info"
          [matTooltip]="chatCookies">
        </mat-icon>
      </label>
      <mat-checkbox color="primary" formControlName="intercom"></mat-checkbox>
    </article>
  </form>

  <footer>
    <button test-id="cookies" mat-flat-button color="primary" (click)="saveCookies()" i18n>Save preferences</button>
    <button mat-button color="primary" (click)="confirmCookies()" i18n>Decline all</button>
  </footer>
</section>