<global-modal>
  <mat-card appearance="outlined">
    <mat-card-content>
      <cookie-form [form]="form"></cookie-form>
      <section fxLayout fxLayoutAlign="center center">
        <button class="save-btn" mat-flat-button color="primary" (click)="save()" i18n>Save & Exit</button>
      </section>
      <section class="divider">
        <mat-divider [inset]="true"></mat-divider>
      </section>
      <button mat-button (click)="openPrivacyPolicy()" i18n>Privacy Policy</button>
      <button mat-button (click)="openCookiesPolicy()" i18n>Cookies Policy</button>
    </mat-card-content>
  </mat-card>
</global-modal>