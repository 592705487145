import { Media, RightType, Territory } from '../static';
import { NodeType } from './node';
import { Right, RightVersion } from './right';
import { WaterfallSource } from './waterfall';

export const SPACING = 32;
const SOURCE_WIDTH = 400;
const SOURCE_HEIGHT = 70;
const RIGHT_WIDTH = 240;
const RIGHT_HEIGHT = 180;
const VERTICAL_WIDTH = RIGHT_WIDTH + (SPACING * 2);
const LEVEL_HEIGHT = 112;

export interface Arrow {
  path: string;
  parentId: string;
  childId: string;
  labelPosition: { x: number, y: number };
}

interface NodeBase {
  id: string;
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: NodeType;
  children: string[];
  version: Record<string, RightVersion>;
  pools: string[];
  duplicateOf?: string; // Id of duplicated node. Temporary, only used on creation to copy conditions
}

function createNodeBase(params: Partial<NodeBase> = {}): NodeBase {
  return {
    id: '',
    name: '',
    x: 0, y: 0,
    width: 0,
    height: 0,
    type: 'right',
    children: [],
    version: {},
    pools: [],
    ...params
  }
}

export interface SourceNode extends NodeBase {
  type: 'source';
  medias: Media[];
  territories: Territory[];
}

export function createSourceNode(params: Partial<SourceNode> = {}): SourceNode {
  const node = createNodeBase(params);
  return {
    ...node,
    type: 'source',
    width: SOURCE_WIDTH,
    height: SOURCE_HEIGHT,
    medias: [],
    territories: [],
    ...params
  }
}

export interface RightNode extends NodeBase {
  type: 'right';
  color: string;
  rightHolderId: string;
  contractId?: string;
  percent: number;
  rightType: RightType;
}

export function createRightNode(params: Partial<RightNode> = {}, isHidden = false): RightNode {
  const node = createNodeBase(params);
  return {
    ...node,
    type: 'right',
    width: isHidden ? 0 : RIGHT_WIDTH,
    height: isHidden ? 0 : RIGHT_HEIGHT,
    color: '#000',
    rightHolderId: '',
    percent: 0,
    rightType: 'empty',
    ...params
  }
}

export interface VerticalNode extends NodeBase {
  type: 'vertical';
  members: RightNode[];
  percent: number;
}

export function createVerticalNode(params: Partial<VerticalNode> = {}): VerticalNode {
  const node = createNodeBase(params);
  return {
    ...node,
    type: 'vertical',
    members: [],
    percent: 100,
    height: getVerticalGroupHeight(params.members),
    width: VERTICAL_WIDTH,
    ...params
  }
}

export function getVerticalGroupHeight(members: RightNode[] = []) {
  return RIGHT_HEIGHT + (LEVEL_HEIGHT * (members.length - 1)) + (SPACING * (members.length + 1));
}

export interface HorizontalNode extends NodeBase {
  type: 'horizontal';
  members: (VerticalNode | RightNode)[];
  percent: number;
  blameId: string;
}

export function createHorizontalNode(params: Partial<HorizontalNode> = {}): HorizontalNode {
  const node = createNodeBase(params);
  const members = params.members || [];
  return {
    ...node,
    type: 'horizontal',
    members: [],
    height: members.length > 0 ? (Math.max(...members.map(m => m.height)) + (SPACING * 2)) : RIGHT_HEIGHT,
    width: members.length > 0 ? getHorizontalGroupWidth(members) : RIGHT_WIDTH,
    percent: 100,
    blameId: '',
    ...params
  }
}

function getHorizontalGroupWidth(members: (RightNode | VerticalNode)[] = []) {
  const nonHiddenRightMemberCount = members.filter(m => m.width !== 0 && m.height !== 0 && m.type === 'right').length;
  const nonHiddenVerticalMemberCount = members.filter(m => m.width !== 0 && m.height !== 0 && m.type === 'vertical').length;
  const nonHiddenMemberCount = nonHiddenRightMemberCount + nonHiddenVerticalMemberCount;
  const rightsWidth = RIGHT_WIDTH * nonHiddenRightMemberCount;
  const verticalWidth = VERTICAL_WIDTH * nonHiddenVerticalMemberCount;
  const spacing = SPACING * (nonHiddenMemberCount + 1);
  return rightsWidth + verticalWidth + spacing;
}

export type Node = SourceNode | RightNode | VerticalNode | HorizontalNode;

interface GraphItems { rights: Right[]; sources: WaterfallSource[] }
export interface GraphDiff { created: GraphItems; updated: GraphItems; deleted: GraphItems }
export function computeDiff(oldGraph: GraphItems, newGraph: GraphItems): GraphDiff {

  const createdRights = newGraph.rights.filter(right => !oldGraph.rights.some(r => r.id === right.id));
  const updatedRights = newGraph.rights.filter(right => oldGraph.rights.some(r => r.id === right.id));
  const deletedRights = oldGraph.rights.filter(right => !newGraph.rights.some(r => r.id === right.id));

  const createdSources = newGraph.sources.filter(source => !oldGraph.sources.some(s => s.id === source.id));
  const updatedSources = newGraph.sources.filter(source => oldGraph.sources.some(s => s.id === source.id));
  const deletedSources = oldGraph.sources.filter(source => !newGraph.sources.some(s => s.id === source.id));

  return {
    created: { rights: createdRights, sources: createdSources },
    updated: { rights: updatedRights, sources: updatedSources },
    deleted: { rights: deletedRights, sources: deletedSources },
  };
}

export function findRightNode(nodeId: string, step = 0, graph: Node[] = []) {
  let node = graph.find(n => n.id === nodeId);
  if (!node) {
    const horizontal = graph.filter(n => n.type === 'horizontal') as HorizontalNode[];
    for (const h of horizontal) {
      node = h.members.find(m => m.id === nodeId);
      if (!node) continue;
      if (node && node.type !== 'vertical') break;
      node = (node as VerticalNode).members[step];
      break;
    }
  } else if (node.type === 'vertical') {
    node = node.members[step];
  }

  return node as RightNode;
}