<section fxLayout="row" fxLayoutAlign="center">
  <div class="surface" [ngClass]="{ dialog: this.dialog }">
      <article>
        <p>
          The following websites and platforms are edited by the company CASCADE 8, whose
          registered office is 4 rue de la République 69001 Lyon, France, registered under the
          Commercial Registry of Lyon under number 847 756 764:
        </p>
        <ul>
          <li>ARCHIPEL MARKET</li>
          <li>ARCHIPEL CONTENT</li>
          <li>MEDIA FINANCIERS</li>
          <li>BLOCKFRAMES</li>
        </ul>
        <p>
          CASCADE 8 is committed to safeguarding Your privacy. Contact us at team&#64;cascade8.com if
          You have any questions or problems regarding the use of Your Personal Data and we will gladly
          assist You.
        </p>
        <p>
          By using any of these websites, platforms or/and our services (hereinafter designated as
          "<strong>Website</strong>" and "<strong>Service</strong>") and accepting appropriate acceptance box of our
          Privacy Policy, You
          consent to the Processing of Your Personal Data as described in this Privacy Policy.
        </p>
        <p>
          Words with a capital letter that are not defined in this Privacy Policy are defined in the relevant T&Cs.
        </p>
      </article>

      <article>
        <h3>Table of Contents</h3>

        <ol>
          <li>Definitions used in this Policy</li>
          <li>Data protection principles we follow</li>
          <li>What rights You have regarding Your Personal Data</li>
          <li>What Personal Data we collect about You directly</li>
          <li>Trusted Partners collecting Personal Data for Us and having access to Your Personal Data / Transfers of Persona Data</li>
          <li>How long we keep Your Personal Data</li>
          <li>How we secure Your Personal Data</li>
          <li>Children</li>
          <li>Information about cookies</li>
          <li>Contact information</li>
        </ol>
      </article>

      <article>
        <h3>1. Definitions</h3>
        <p>
          <strong>Client</strong> – refers to organization who subscribes an access to the Service.
        </p>
        <p>
          <strong>Data Controller</strong> – refers to the person which determines the purposes and means of the Processing of Personal Data – under this Privacy Policy, for some Processing, CASCADE 8.
        </p>
        <p>
          <strong>Data Processor</strong> – refers to the person which Processes Personal Data on behalf of the Data Controller – under this Privacy Policy, for some Processing, CASCADE 8 is the Data Processor and the Client is the Data Controller.
        </p>
        <p>
          <strong>GDPR</strong> – refers to the General Data Protection Regulation n°2016/679.
        </p>
        <p>
          <strong>Personal Data</strong> – any information relating to an identified or identifiable natural person.
        </p>
        <p>
          <strong>Processing/Processed</strong> – any operation or set of operations which is performed on Personal
          Data or on sets of Personal Data.
        </p>
        <p>
          <strong>You/Your</strong>(either capitalized or not) – refers to the natural person reading this Privacy
          Policy,
          whose Personal Data is being Processed.
        </p>
        <p>
          <strong>We/Us</strong>(either capitalized or not) – CASCADE 8.
        </p>
        <p>
          <strong>T&Cs</strong> – the Terms and Conditions of the Website that You currently use and is covered by
          this Privacy Policy.
        </p>
        <p>
          <strong>Trusted Partners</strong> – refers to the person which processes Personal Data on behalf of Us.
        </p>
      </article>

      <article>
        <h3>2. Personal Data protection principles</h3>
        <p>
          As Data Controller, We undertake to follow the following Personal Data protection principles:
        </p>
        <ul>
          <li>
            Processing is lawful, fair, transparent. Our Processing activities have lawful
            grounds. We always consider Your rights before Processing Personal Data. We
            will provide You information regarding Processing upon request.
          </li>
          <li>
            Processing is limited to the purposes as defined in paragraphs 4 and 5 below for
            the related Website that is concerned. Our Processing activities fit the purpose
            for which Personal Data was collected directly by Us or by Our Trusted Partners,
            under Our instruction.
          </li>
          <li>
            Processing is done with minimal Personal Data. We only gather and Process the
            minimal amount of Personal Data required for the below defined purposes.
          </li>
          <li>
            Processing is time-limited. We will not store Your Personal Data for longer than needed.
          </li>
          <li>
            We will do our best to ensure the accuracy of Personal Data.
          </li>
          <li>
            We will do our best to ensure the integrity and confidentiality of Your Personal Data.
          </li>
        </ul>
      </article>

      <article>
        <h3>3. Your rights</h3>
        <p>You have the following rights:</p>
        <ol>
          <li>
            Right to information – meaning You have the right to know whether Your Personal Data
            is being Processed; what Personal Data is collected, from where it is obtained and why
            and by whom it is Processed.
          </li>
          <li>
            Right to access – meaning You have the right to access the Personal Data collected
            from/about you. This includes Your right to request and obtain a copy of Your Personal
            Data collected.
          </li>
          <li>
            Right to rectification – meaning You have the right to request rectification or erasure of
            Your Personal Data that is inaccurate or incomplete.
          </li>
          <li>
            Right to erasure – meaning in the seven (7) following grounds You can request for Your
            Personal Data to be erased from our records:
            <ul>
              <li>Your Personal Data is used for canvassing purposes;</li>
              <li>
                Your Personal Data is no longer necessary in relation to the purposes for
                which such Personal Data is collected or processed by Us;
              </li>
              <li>
                You request to withdraw Your consent to the Processing of Your Personal
                Data by Us and where there is no other legal ground for the Processing;
              </li>
              <li>
                You object to the Processing of Your Personal Data by Us and there is no
                overriding legitimate grounds for the Processing,
              </li>
              <li>Your Personal Data have been unlawfully processed;</li>
              <li>
                Your Personal Data have to be erased for compliance with a legal obligation in
                European Union law or Member state law to which We are subject;
              </li>
              <li>
                Your Personal Data have been collected when You were a minor in relation to
                the offer of information society services (blog, forum, social network,
                websites, etc.).
              </li>
            </ul>
          </li>
          <li>
            Right to restrict Processing – meaning where one of the four (4) following situations
            applies, you have the right to restrict the Processing of Your Personal Data:
            <ul>
              <li>
                When You have exercised Your right to rectification: You contest the accuracy
                of Your Personal Data, for a period enabling Us to verify the accuracy of Your
                Personal Data;
              </li>
              <li>
                When You have objected to Processing, pending the verification whether Our
                legitimate grounds override those of Your Personal Data;
              </li>
              <li>
                When We no longer need Your Personal Data for the purposes of the
                Processing, but You require them for the establishment, exercise or defense
                of legal claims,
              </li>
              <li>
                When the Processing is unlawful, and You oppose the erasure of Your
                Personal Data and request the restriction of their use instead.
              </li>
            </ul>
          </li>
          <li>
            Right to object to Processing – meaning in certain cases You have the right to object to
            Processing of Your Personal Data, for example in the case of direct marketing.
          </li>
          <li>
            Right to object to automated Processing – meaning You have the right to object to
            automated Processing, including profiling; and not to be subject to a decision based
            solely on automated Processing. You can exercise this right not to be subject to a
            decision based solely on an automated Processing anytime the results of such decision
            produce legal effects or affects You significantly.
          </li>
          <li>
            Right to Personal Data portability – You have the right to obtain Your Personal Data in a
            machine-readable format or if it is technically possible, as a direct transfer from one
            processor to another.
          </li>
          <li>
            Right to file a complaint – in the event that We refuse Your request under the right to
            access, We will justify Our refusal. If You are not satisfied with the way Your request has
            been handled, please contact Us.
          </li>
          <li>
            Right for the help of supervisory authority – meaning You have the right to seek help
            from the applicable supervisory authority and the right for other applicable legal
            remedies such as claiming damages.
          </li>
          <li>
            Right to withdraw consent – You have the right to withdraw any given consent for
            Processing of Your Personal Data.
          </li>
        </ol>
        <p>When CASCADE 8 acts as Data Processor, We ensure to transmit any of Your request to the Data Controller, in accordance with the GDPR.</p>
      </article>

      <article>
        <h3>4. Personal Data we collect directly from You</h3>
        <div class="table-wrapper">
          <table border="1px solid">
            <tr>
              <th>Personal Data</th>
              <th>MEDIA FINANCIERS (MF)</th>
              <th>ARCHIPEL CONTENT (AC)</th>
              <th>ARCHIPEL MARKET (AM)</th>
              <th>BLOCKFRAMES (BF)</th>
              <th>Purposes</th>
              <th>Legal basis</th>
              <th>Access to other Users to the Personal Data</th>
              <th>CASCADE 8 as Data Controller / Data Processor</th>
            </tr>
            <tr>
              <td>
                Your name, Your surname, Your email address, Your avatar, Your phone number, The date of the creation of Your account, Last connexion date, Your role in the company, Your IP address
              </td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                To perform and deliver the Service (verification of identity, giving access to the database,
                displaying information to other users, audience analysis, getting help from support)
              </td>
              <td>Consent / Contractual execution</td>
              <td>YES</td>
              <td>Data Processor</td>
            </tr>
            <tr>
              <td>Your profile pictures, </td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>To allow the management of Your user space</td>
              <td>Consent</td>
              <td>YES</td>
              <td>Data Processor</td>
            </tr>
            <tr>
              <td>Your name, Your surname, Your role in the company, Your email, Your phone number, The legal representative of the Client, The history with the Clients, Sales tracking, Communication data</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>To manage Our contractual relationship: invoicing and auditing</td>
              <td>Contractual execution or legal obligation</td>
              <td>YES</td>
              <td>Data Controller</td>
            </tr>
            <tr>
              <td>As a User Buyer of ARCHIPEL MARKET, Your buying preferences (genre, territories, rights, languages)</td>
              <td></td>
              <td></td>
              <td>X</td>
              <td></td>
              <td>
                To perform and deliver the Service (verification of identity, giving access to the database, displaying information to other users, audience analysis, getting help from support)
              </td>
              <td>Consent / Contractual execution</td>
              <td>YES</td>
              <td>Data Processor</td>
            </tr>
            <tr>
              <td>
                Your email address
              </td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
              <td>To send the newsletters</td>
              <td>Consent</td>
              <td>NO</td>
              <td>Data Controller</td>
            </tr>
            <tr>
              <td>
                Your email address, Your Name, Your Surname, Your role in the Client company, Your email address,Your phone number (optional)
              </td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>To ask for a demo of the Service</td>
              <td>Consent</td>
              <td>NO</td>
              <td>Data Controller</td>
            </tr>
          </table>
        </div>
        <p>
          Moreover, We reserve the right to anonymize Personal Data collected and to use any such
          Personal Data for purposes permitted by law. We will use Personal Data outside the scope of
          this Policy only when it is anonymized, notably for statistical purposes.
        </p>
        <p>
          When We act as Data Controller, We might Process Your Personal Data for additional purposes that are not mentioned here,
          but are compatible with the original purpose(s) for which the Personal Data was collected.
          To do this, We will ensure that:
        </p>
        <ul>
          <li>
            the connection between purposes, context and nature of Your Personal Data is suitable
            for additional Processing;
          </li>
          <li>such additional Processing would not harm Your interests and</li>
          <li>there would be appropriate safeguards for such additional Processing.</li>
        </ul>
        <span>We will inform You of any additional Processing and purposes.</span>
        <p>You are informed that some of Your Personal Data are visible to other Users.</p>
      </article>

      <article>
        <h3>5. Trusted Partners collecting Personal Data for Us and having access your Personal Data / transfers of Personal Data</h3>
        <p>
          We do not share Your Personal Data with third parties outside of Our Trusted Partners (see
          below) and Users of Our Service, as described above. Your Personal Data is in some cases
          provided to other users of Our Service which would like to contact You in connection with
          the Service (as authorized under the T&Cs), Our Trusted Partners in order to either provide
          You with the Service or enhance Your user experience.
        </p>
        <p>
          In case of transfers of Personal Data to third countries, We insure that there is an appropriate level of protection.
        </p>
        <div class="table-wrapper">
          <table border="1px solid">
            <tr>
              <th>MF</th>
              <th>AC</th>
              <th>AM</th>
              <th>BF</th>
              <th>TRUSTED PARTNERS</th>
              <th>PERSONAL DATA</th>
              <th>PURPOSES</th>
              <th>LINK TO PRIVACY POLICY</th>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>Firebase Authentication (Google Ireland Ltd) Place of processing: Ireland</td>
              <td>id, email address, password, account creation date, last connection date, cookies</td>
              <td>Verify user's identity, give access to the database.</td>
              <td><a href="https://firebase.google.com/terms/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Firebase – Firestore (Google Ireland Limited) Place of Processing: Ireland
              </td>
              <td>
                id, name, surname, email address, phone number, position,
                company name, company address, company phone number, avatar
              </td>
              <td>Display information to authorized users</td>
              <td><a href="https://firebase.google.com/terms/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Google Analytics (Google LLC)
                Place of Processing: United States
              </td>
              <td>
                id, name, surname, email address, IP address, browser, device, OS, pages visited, time spent on
                each page, clicks on page elements, cookies
              </td>
              <td>Audience analysis to design better experience, targeted advertising</td>
              <td><a href="https://firebase.google.com/terms/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Google Tag Manager (Google Ireland Limited) Place of Processing: Ireland
              </td>
              <td>
                id, name, surname, email address, IP address, browser, device, OS,
                pages visited, time spent on each page, clicks on page elements, cookies
              </td>
              <td>Audience analysis to design better experience</td>
              <td><a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Google Big Query (Google Ireland Limited) Place of Processing: Ireland
              </td>
              <td>
                id, name, surname, email address, IP address, browser, device, OS, pages
                visited, time spent on each page, clicks on page elements, cookies
              </td>
              <td>Audience analysis to design better experience</td>
              <td><a href="https://cloud.google.com/terms/service-terms" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Intercom (Intercom Inc) Place of Processing: United States
              </td>
              <td>
                id, name, surname, email address, IP address, browser, device, OS, pages visited, time spent on
                each page, clicks on page elements, cookies
              </td>
              <td>Get help from our support</td>
              <td><a href="https://www.intercom.com/legal/terms-and-policies" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Hotjar (Hotjar, Ltd) Place of Processing: Malta
              </td>
              <td>
                id, name, surname, email address, IP address, browser, device, OS, pages visited,
                time spent on each page, clicks on page elements, cookies
              </td>
              <td>Audience analysis to design better experience</td>
              <td><a href="https://www.hotjar.com/legal/policies/privacy/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>Sendgrid (Twilio, Inc.) Place of Processing: United States</td>
              <td>id, name, surname, email address</td>
              <td>Email personalization</td>
              <td><a href="https://www.twilio.com/legal/tos" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>Algolia (Algolia, Inc.) Place of Processing: United States</td>
              <td>id, name, surname, email address, avatar, position, phone number</td>
              <td>Information used as search parameters</td>
              <td><a href="https://www.algolia.com/policies/terms/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>JWPlayer (Longtail Ad Solutions, Inc.) Place of Processing: United States</td>
              <td>
                id, name, surname, browser, device, OS, bandwidth, video player loading, click on video player,
                video player viewing time
              </td>
              <td>Display information to authorized users & audience analysis to design better experience</td>
              <td><a href="https://www.jwplayer.com/tos/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Sentry (Functional Software Inc.) Place of Processing: United States
              </td>
              <td>id, name, surname, email address, IP address, browser, device, OS </td>
              <td>Give context for error solving</td>
              <td><a href="https://sentry.io/terms/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                PipeDrive Place of Processing: Estonia
              </td>
              <td>Last name, first name, email, phone, position, interaction history with client, sales tracking, communication data.</td>
              <td>manage contractual relationship</td>
              <td><a href="https://www.pipedrive.com/en/privacy" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                MailChimp Place of Processing: United States
              </td>
              <td>Email address</td>
              <td>Send newsletters</td>
              <td><a href="https://www.intuit.com/privacy/statement/" target="_blank">Privacy Policy</a></td>
            </tr>
            <tr>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>
                Airtable Place of Processing: United States
              </td>
              <td>Last name, first name, email, user's IP, browser, device, OS, login history, visited pages, time spent on each page, clicks on page elements.</td>
              <td>Audience analysis to design better experience</td>
              <td><a href="https://www.airtable.com/company/privacy" target="_blank">Privacy Policy</a></td>
            </tr>
          </table>
        </div>
        <p>
          We only work with Trusted Partners who are able to ensure adequate level of protection of
          Your Personal Data. We comply with the provisions of Article 28 of the GDPR to govern our
          contractual relations with them. We disclose Your Personal Data to third parties or public
          officials when We are legally obliged to do so. We might disclose Your Personal Data to third
          parties if You have consented to it or if there is other legal grounds for it.
        </p>
        <h3>FOR some of the Services ONLY:</h3>
        <p>You expressly accept to share some of Your Personal Data with other Users, and notably:</p>
        <p>
          For ARCHIPEL MARKET: Your name, organization, email address, company activity, role, titles You added in Your
          Wishlist, titles viewed, titles You requested a screening for, titles You requested asking price
          for, titles You click on promo elements, Your average watch time on a screening.
        </p>
        <p>
          For ARCHIPEL CONTENT : 
        </p>
        <ul>
          <li>For the Personal Data of the buyers available to the sellers : titles viewed, adds to wishlist, clicks on promotional elements, Your asking price requests, Your offer, Your counter offer proposal, Your company </li>
          <li>For the Personal Data of the sellers available to the buyers : Your titles available rights and territories, Your asking price requests, Your titles descriptive information, Your company</li>
        </ul>
        <p>For MEDIA FINANCIERS : Your name, organization, email address, company activity, role, projects viewed, adds to wishlist, clicks of promo elements, projects’ details, funding status.</p>
        <p>For BLOCKFRAMES, access to Your Personal Data is set by the main administrator according to Your role : role, title, company details, waterfall</p>
      </article>

      <article>
        <h3>6. How long We keep Your Personal Data</h3>
        <p>
          When We act as a Data Controller: We retain Your Personal Data for the duration of Your use of the Service and for a period of
          maximum 5 years from the end of use for evidential purposes.
        </p>
        <p>When We act as a Data Processor: We retain your Personal Data as long as the Client subscribes the Service.</p>
      </article>

      <article>
        <h3>7. How we secure your Personal Data </h3>
        <p>
          We do our best to keep Your Personal Data safe. We use safe protocols for communication
          and data transferring (such as HTTPS). We use anonymization and pseudonymization where
          suitable. We monitor Our systems for possible vulnerabilities and attacks.
          Even though We make Our best efforts to this end, We cannot guarantee the security of
          information. However, We undertake to notify suitable authorities of data breaches. We will
          also notify You if there is a threat to Your rights or interests. We will do everything We
          reasonably can to prevent security breaches and to assist authorities should any breaches
          occur.
        </p>
        <p>Note that You have to keep Your account username and password secret.</p>
      </article>

      <article>
        <h3>8. Children</h3>
        <p>
          We do not intend to collect or knowingly collect information from children. We do not target
          children with Our Services.
        </p>
      </article>

      <article>
        <h3>9. Cookies and other technologies We use</h3>
        <p>
          We use cookies and/or similar technologies to analyze customer behavior, administer the
          Website, track users' movements, and collect information about Users. This is done in order
          to personalize and enhance Your experience with Our Service.
        </p>
        <p>
          For more information about our cookies, our cookies policy is <a [routerLink]="['/auth','cookies']" target="_blank">available here</a>.
        </p>
      </article>

      <article>
        <h3>10. Contact Information</h3>
        <h4>Supervisory Authority</h4>
        <p>Commission Nationale de l'Informatique et des Libertés</p>
        <p>3 place de Fontenoy, 75007 Paris</p>
        <p>+33 (0) 1 53 73 22 22</p>
        <h4>Changes to this Privacy Policy</h4>
        <p>We reserve the right to make change to this Privacy Policy.</p>
      </article>
  </div>
</section>