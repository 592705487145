<global-modal>
  <header>
    <a mat-icon-button (click)="close()">
      <mat-icon svgIcon="arrow_back"></mat-icon>
    </a>
    <h1>COOKIES POLICY</h1>
  </header>

  <auth-cookies-policy-text dialog></auth-cookies-policy-text>
</global-modal>
